.class-label-card {
  padding: 30px;

  .top-content {
    margin: 0;
  }

  .menu {
    float: right;
    margin-right: -15px;
  }

  .icon {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    color: #252525;
    line-height: 38px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.02em;
    margin-top: 10px;
    margin-bottom: -10px;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    color: #7d819d;
    text-transform: uppercase;
  }

  .text-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #252525;
  }

  .text-label {
    font-size: 14px;
    line-height: 22px;
    color: #aab0c6;
  }

  .line {
    height: 0.8px;
    margin: 20px -8px 26px -8px;
    border-top: 1px solid #f3f3f3;

    &.second {
      margin-top: 26px;
    }
  }

  .loading {
    display: flex;
    background-color: #e2e2e2;
  }
}
