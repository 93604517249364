.notifications-page {
  .bx--search-magnifier {
    fill: #aab0c6;
  }

  .pane {
    padding: 45px;
  }

  .activate {
    margin-top: 45px;
    margin-left: 25px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;

    .span-toggle {
      margin-top: -7px;
      margin-left: 25px;
      float: right;
    }
  }

  .bx--toggle-input:checked
    + .bx--toggle-input__label
    > .bx--toggle__switch::before {
    background: #eef1fa;
    border: 1px solid #164d9d;
    box-sizing: border-box;
    border-radius: 50px;
  }

  .bx--toggle-input--small:checked
    + .bx--toggle-input__label
    .bx--toggle__check {
    fill: #eef1fa;
    transform: scale(1) translateX(1rem);
  }

  .bx--toggle-input:checked
    + .bx--toggle-input__label
    > .bx--toggle__switch::after {
    background-color: #164d9d;
  }

  .bx--data-table {
    .toggle {
      margin-top: -8px;
    }

    th {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      color: #252525;
      padding-top: 20px;
      padding-bottom: 10px;
      border-color: #fff;
      background: #fff;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
      }
    }

    td {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;

      color: #000000;
      background: #fff;
      border-color: #fff;
    }
  }

  .btn {
    margin-top: 25px;
    width: 215px;
  }
}
