.error-page {
  font-family: Segoe UI;
  font-style: normal;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .error-div {
    margin-left: 52px;
  }

  .error {
    font-weight: normal;
    font-size: 217px;
    letter-spacing: -0.005em;
    color: #d42626;
    margin-bottom: 2px;
  }

  .textline {
    font-weight: 600;
    font-size: 34px;
    letter-spacing: -0.005em;
    margin-left: 15px;
    color: #939090;
    span {
      font-weight: bold;
      color: #4d4b4b;
      cursor: pointer;
    }
  }
}
