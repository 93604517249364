.navigation-card {
  width: 100%;
  max-width: 300px;
  padding-top: 30px;
  padding-bottom: 16px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

  .top-content {
    margin: 0 29px;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    color: #252525;
    line-height: 38px;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: -0.02em;
    margin-top: 10px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
    margin-bottom: 0;
    color: #7d819d;
    line-height: 19px;
    text-transform: uppercase;
  }

  .icon {
    width: 46px;
    height: 46px;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
  }

  .line {
    height: 0.8px;
    margin: 20px 0 10px 0;
    border-top: 1px solid #f3f3f3;
  }

  .line2 {
    margin-top: 5px;
  }

  .link {
    padding-left: 29px;
    padding-right: 29px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #164d9d;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
  }

  .link-icon {
    stroke-width: 3;
    color: #164d9d;
  }

  .btn {
    width: 100%;
    background: #164d9d;
  }

  .bottom {
    margin: 17px;
    margin-top: 15px;
  }
}
