.main-loader-modal {
  z-index: 10000;
  .bx--modal-container {
    width: 350px;
    border-radius: 2px;
  }

  .bx--modal-header {
    display: none;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding: 30px;
    margin-bottom: 0;
  }

  .loader {
    width: 30px;
  }
}
