.students-list-modal {
  .bx--modal-container {
    width: 900px;
    background: #ffffff;
    border-radius: 2px;
    max-width: 100%;
  }

  .bx--modal-header {
    display: none;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .school-logo {
    margin-top: 45px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #7d819d;
    background-size: cover;
  }

  .hr {
    height: 1px;
    border: 1px solid #e7e7e7;
  }

  .info {
    padding: 22px 15px;
    border-top: 1px solid #e7e7e7;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
  }

  th {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #252525;
    background: #f3f3f3;
    padding-top: 17px;
    padding-bottom: 13px;
  }

  td {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: #565656;
    background: #f9f9fb;
  }

  @media print {
    th,
    td,
    .info {
      font-size: 13px !important;
    }

    .school-logo {
      display: block !important;
      -webkit-print-color-adjust: exact !important;
    }
  }
}
