.forgot-password {
  .logo-div {
    .version {
      margin-left: 10px;
      font-size: 10px;
      margin-top: -1px;
      display: block;
    }

    .text-div {
      margin-top: 3px;
    }
  }
}
