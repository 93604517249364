.budget-report-modal {
  z-index: 10000 !important;

  .bx--modal-container {
    width: 948px;
    background: #ffffff;
    border-radius: 2px;
  }

  .bx--modal-header {
    display: none;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding-left: 57px;
    padding-right: 57px;
  }

  .school-logo {
    margin-top: 45px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #7d819d;
    background-size: cover;
  }

  .hr {
    height: 1px;
    border: 1px solid #e7e7e7;
  }

  th {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #f9f9fb;
    padding-top: 17px;
    padding-bottom: 13px;
    width: 20%;
  }

  td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #565656;
    width: 20%;
  }

  .table-row-bg-primary {
    td,
    th {
      background: #164d9d;
      color: #fff;
    }
  }
  .row-total {
    td,
    th {
      background: #f3f3f3;
      font-weight: bold;
    }

    &.text-primary {
      td,
      th {
        color: #164d9d;
      }
    }
    &.text-dark {
      td,
      th {
        color: #252525;
      }
    }
  }

  @media print {
    position: relative !important;

    .not-authentic {
      display: block;
    }

    .bx--modal-container {
      width: 100% !important;
      background: #ffffff;
      border-radius: 2px;
    }

    .bx--modal-content {
      padding: 0;
      margin: 0;
    }

    th,
    td,
    .info {
      font-size: 13px !important;
    }

    .pic,
    .school-logo {
      display: block !important;
      -webkit-print-color-adjust: exact !important;
    }
  }
}
