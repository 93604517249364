.fees-mini-bar {
  background-color: #f9f9fb;
  border-radius: 2px;
  padding: 16px 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .t-c {
  }

  .name {
    font-size: 14px;
    line-height: 22px;
    color: #8b91a6;
  }

  .value {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #252525;
  }

  .edit {
  }

  .icon-edit {
    margin-bottom: -5px;
  }

  .text-edit {
    margin-top: -10px;
    padding-left: 5px;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #164d9d;
  }

  .delete {
    margin-left: 10px;
  }
}
