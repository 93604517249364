.ledger {
  .card-add-ledger-category {
    padding-top: 17px !important;
    height: 100px !important;
    text-align: center;
    background: #164d9d;
    border-radius: 2px;
    color: white;

    .text-label {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .bx--search-magnifier {
    fill: #aab0c6;
  }
}
