.class-card {
  padding: 30px;
  margin-bottom: 40px;
  cursor: pointer;

  &:hover {
    background-color: rgba($color: #164d9d, $alpha: 0.03);
  }

  .top-content {
    margin: 0;
  }

  .menu {
    float: right;
    margin-right: -15px;
  }

  .icon {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    color: #252525;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: -0.02em;
    margin-top: 10px;
    margin-bottom: -05px;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    color: #7d819d;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .text-no-student {
    font-size: 14px;
    line-height: 22px;
    color: #7d819d;
  }

  .text-label {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #aab0c6;
  }

  .text-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #252525;
  }

  .add-student,
  .add-fees {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #164d9d;
    margin: 20px 0;
  }

  .line {
    height: 0.8px;
    margin: 15px -8px 15px -8px;
    border-top: 1px solid #f3f3f3;
  }

  .divider-div {
    .divider {
      height: 50px;
      width: 1px;
      margin-top: -5px 5px 0 5px;
      border: 1px solid #f3f3f3;
    }
  }

  .loading {
    display: flex;
    background-color: #e2e2e2;

    &.text-value {
      height: 20px;
      width: 100%;
      margin-top: 5px;
    }
  }
}
