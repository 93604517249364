.student-summary-card {
  display: flex;
  background: #ffffff;
  border-radius: 2px;
  padding: 15px 20px;

  cursor: pointer;

  &:hover {
    background-color: rgba($color: #164d9d, $alpha: 0.03);
  }

  &.true {
    background-color: #164d9d;

    .info {
      .title,
      .value {
        color: #fff;
      }
    }
  }

  .icon {
    width: 30%;
  }

  .info {
    .title {
      color: #7d819d;
      font-size: 13px;
      text-transform: uppercase;
    }

    .value {
      font-weight: 600;
      margin-top: -5px;
      font-size: 23px;
      line-height: 38px;
      /* identical to box height */
      letter-spacing: -0.05em;

      /* b */
      color: #252525;
    }
  }
}
