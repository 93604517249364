.expandable-pane {
  border-radius: 2px;
  margin-top: 20px;
  .exp-title {
    &.true {
      width: 300px;
    }
  }

  .exp-pane-header {
    min-height: 100px;
    padding: 20px 40px 10px 30px;
    background-color: #fff;
  }

  &.exp-primary {
    .exp-pane-header {
      background-color: #164d9d;
    }
    .text-primary,
    .text-dark {
      color: #fff;
    }
  }

  .exp-hearder-content {
    padding: 0px 20px;
  }

  .exp-hearder-content {
    flex: 1;
  }

  .exp-inner-container {
    background-color: #fff;
    padding: 10px 30px 10px 25px;
    border-top: solid 1px #efefef;
  }

  &.true {
    .exp-inner-pane {
      padding-left: 35px;
    }
    .exp-inner-container {
      padding: 0px;
      padding-bottom: 10px;
    }
  }

  &.true .exp-subcomponent {
    margin-top: 0px;
    border-top: solid 1px #efefef;

    .exp-inner-pane {
      padding: 0px !important;
    }
    .exp-inner-container {
      padding: 10px 30px 10px 25px !important;
    }
  }
}
