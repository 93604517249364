.ledger-account {
  background-color: #f9f9fb;
  border-radius: 2px;
  padding: 15px 20px;
  display: flex;
  align-items: center;

  .name {
    font-weight: 500;
    color: #252525;
    width: 80%;
  }

  .delete {
    margin-left: 30px;
  }

  &.loading {
    height: 50px;
    background-color: #e2e2e2;

    &::after {
      content: '';
      width: 100%;
      height: 77px;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.5),
        transparent
      );
      animation: loading 1.5s infinite;
    }
  }

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
}
