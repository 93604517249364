.not-found-page {
  font-family: 'Axiforma Regular';
  font-style: normal;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .sorry-div {
    margin-left: 52px;
  }

  .sorry {
    font-weight: 300;
    font-size: 224px;
    line-height: 129.51%;
    color: #585555;
    margin-bottom: 2px;
  }

  .textline {
    font-weight: 300;
    font-size: 40px;
    line-height: 53px;
    letter-spacing: -0.04em;
    color: #939090;
    max-width: 675px;
    margin-left: 15px;
    span {
      font-weight: bold;
      color: #2186eb;
      cursor: pointer;
    }
  }
}
