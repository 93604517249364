.quick-action-card {
  max-width: 300px;
  padding: 13px 15px 7px 15px;
  background-color: white;
  height: 100px;

  // add color
  &.true {
    background-color: #164d9d;
    .description,
    .value,
    .actions > .link {
      color: #fff;
    }
  }

  .heading {
    display: flex;
    justify-content: center;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    margin-right: 10px;

    svg {
      height: 25px;
      width: 25px;
    }
  }

  .description {
    color: #252525;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.02em;
  }

  .actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    &.true {
      display: block;
    }

    .link {
      color: #164d9d;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      text-decoration: none;
    }

    .line {
      height: 30px;
      width: 1px;
      margin-top: -8px;
      background-color: #f3f3f3;
    }
  }
}
