.budget {
  .add-budget-card {
    padding-top: 15px;
    height: 100px;
    text-align: center;
    background: #164d9d;
    border-radius: 2px;
    color: white;

    .text-label {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
      color: white;
    }
  }

  .bx--search-magnifier {
    fill: #aab0c6;
  }
}
