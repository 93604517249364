.students-attendance {
  .bx--search-magnifier {
    fill: #aab0c6;
  }

  .card-add-receive {
    padding-top: 15px;
    height: 100px;
    text-align: center;
    background: #164d9d;
    border-radius: 2px;
    color: white;

    .text-label {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .bx--form-item {
    width: initial;
  }

  @media print {
    display: none;
  }
}

.student-result-box {
  padding: 20px;
  background-color: #fff;
  position: absolute;
  width: 328px;
  box-shadow: 0px 10px 16px rgba(170, 176, 198, 0.2);
  border-radius: 5px;
  z-index: 1000;

  .student-item {
    cursor: pointer;
  }
}
