.other-payment-receipt-modal {
  z-index: 10000 !important;

  .bx--modal-container {
    width: 876px;
    background: #ffffff;
    border-radius: 2px;
  }

  .bx--modal-header {
    display: none;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding-left: 93px;
    padding-right: 88px;
  }

  .school-logo {
    margin-top: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #7d819d;
    background-size: cover;
  }

  .hr {
    height: 1px;
    border: 1px solid #e7e7e7;
  }

  .payment-info {
    height: 88px;
    padding-top: 22px;
    padding-left: 24px;
    padding-right: 31px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 17px;
  }

  .p-l-r-24-31 {
    padding-left: 24px;
    padding-right: 31px;
  }

  .p-l-24 {
    padding-left: 24px;
  }

  .bill-header {
    background: #f9f9fb;
    border-radius: 2px;
    height: 61px;
  }

  .bx--data-table {
    th {
      color: #164d9d;
    }
  }

  .item-footer {
    margin-top: 20px;
    height: 35px;
    padding-left: 24px;
    padding-right: 31px;
    border-bottom: 1px solid #e7e7e7;
  }

  .not-authentic {
    display: none;
  }

  @media print {
    position: relative !important;

    .not-authentic {
      display: block;
    }

    .bx--modal-container {
      width: 100% !important;
      background: #ffffff;
      border-radius: 2px;
    }

    .bx--modal-content {
      padding: 0;
      margin: 0;
    }

    th,
    td,
    .info {
      font-size: 13px !important;
    }

    .pic,
    .school-logo {
      display: block !important;
      -webkit-print-color-adjust: exact !important;
    }
  }
}
