.nav-menu {
  z-index: 1000;
  position: fixed;
  right: 200px;
  //width: 100vw;
  top: 80px;

  /*   .menu-inner {
    display:flex;
    justify-content: flex-end;
    padding-left: 200px;
    padding-right: 200px;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 140px;


    @media screen and (max-width: 1020px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (max-width: 900px) {
        padding-left: 20px;
        padding-right: 20px;
    }
} */

  @media screen and (max-width: 1366px) {
    right: 100px;
  }

  @media screen and (max-width: 1020px) {
    right: 30px;
  }

  @media screen and (max-width: 900px) {
    right: 20px;
  }

  .heading {
    width: 180px;
    color: #252525;
    font-weight: bold;
    text-align: center;
    background: #ffffff;
    border-radius: 50px;
    padding: 05px;
    display: flex;
    font-size: 12px;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.active,
    &.true {
      background: #164d9d;
      color: #ffffff;
    }

    &:focus {
      outline: none;
    }
  }

  .menu-items {
    position: absolute;
    margin-top: 5px;
    width: 180px;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0px 10px 16px rgba(170, 176, 198, 0.2);
    border-radius: 5px;

    .menu-item {
      cursor: pointer;
      color: #565656;
      font-size: 12px;
      line-height: 19px;
      padding-bottom: 7px;

      &:hover {
        color: #164d9d;
        font-weight: bold;
      }

      .separator {
        border: 1px solid #f3f3f3;
      }
    }
  }

  @media print {
    display: none;
  }
}
