.payroll {
  .bx--search-magnifier {
    fill: #aab0c6;
  }

  .card-add-payroll {
    padding-top: 13px;
    height: 100px;
    text-align: center;
    background: #164d9d;
    border-radius: 2px;
    color: white;

    .text-label {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .status-tag {
    background: #aab0c6;
    opacity: 0.4;
    border-radius: 50px;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #252525;
    min-width: 94px;
    height: 22px;
    padding: 3px 8.55px;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;

    &.success {
      background: #24a249;
      color: #24a249;
    }

    &.pending {
      background: #fc7b1e;
      color: #fc7b1e;
    }
  }
}
