.logo-div {
  background-color: #fff;
  border-radius: 100px;
  padding: 08px 20px;
  width: 83px;
  display: flex;
  align-items: center;

  .logo {
    height: 40px;
  }

  .text {
    color: #152d55;
    margin-left: 10px;
  }
}
