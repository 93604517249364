.notifications {
  position: absolute;
  z-index: 20000 !important;
  top: 50px;
  background-color: #fff;
  box-shadow: 0px 10px 16px rgba(170, 176, 198, 0.2);
  border-radius: 5px;
  height: 320px;
  width: 375px;
  right: 200px;
  padding: 30px 25px;

  .heading {
    color: #252525;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
  }
}
