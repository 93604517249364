.notification-item {
  background: #ffffff;
  box-shadow: 0px 3px 22px rgba(229, 229, 229, 0.5);
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: solid 3px #24a249;
  margin-top: 15px;

  &.read {
    border-left-color: #dde1e6;
  }

  .title,
  .message {
    color: #565656;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    margin-right: 20px;
  }

  .message {
    font-weight: normal;
  }
}
