.student-receipt-modal-2 {
  z-index: 10000 !important;

  .bx--modal-container {
    width: 876px;
    background: #ffffff;
    border-radius: 2px;
    max-width: 100%;
  }

  .bx--modal-header {
    display: none;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .school-logo {
    margin-top: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #7d819d;
    background-size: cover;
  }

  .hr {
    height: 1px;
    border: 1px solid #e7e7e7;
  }

  .student-info {
    height: 88px;
    padding-top: 22px;
    padding-left: 24px;
    padding-right: 31px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 17px;
  }

  .pic {
    margin-top: -15px;
    width: 60px;
    height: 60px;
    background: #c7c8d4;
    border-radius: 50%;
  }

  .p-l-r-24-31 {
    padding-left: 24px;
    padding-right: 31px;
  }

  .p-l-24 {
    padding-left: 24px;
  }

  .bill-header {
    background: #f9f9fb;
    border-radius: 2px;
    height: 55px;
  }

  .bx--data-table {
    th {
      color: #164d9d;
    }
  }

  .item-footer {
    margin-top: 20px;
    height: 90px;
    padding-top: 18px;
    padding-left: 24px;
    padding-right: 31px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }

  .not-authentic {
    display: none;
  }

  @media print {
    .not-authentic {
      display: block;
    }

    th,
    td,
    .info {
      font-size: 14px !important;
    }

    .pic,
    .school-logo {
      display: block !important;
      -webkit-print-color-adjust: exact !important;
    }
  }
}

.signed {
  display: flex;
  margin-top: 100px;
  justify-content: end;
  font-weight: bold;

  .line {
    display: inline-block;
    border-bottom: 2px solid black;
    width: 200px;
    margin-left: 5px;
  }
}

.buttons-footer {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
