.income-statement-modal {
  z-index: 10000 !important;

  .bx--modal-container {
    width: 876px;
    background: #ffffff;
    border-radius: 2px;
    max-width: 100%;
  }

  .bx--modal-header {
    display: none;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding-left: 90px;
    padding-right: 90px;
  }

  .school-logo {
    margin-top: 45px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #7d819d;
    background-size: cover;
  }

  .hr {
    height: 1px;
    border: 1px solid #e7e7e7;
  }

  .bx--data-table {
    th,
    td {
      padding-left: 24px;
      padding-right: 33px;
    }
  }

  .summary-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    height: 61px;
    background: #164d9d;
    color: #ffffff;
    border-radius: 2px;
    padding-left: 24px;
    padding-right: 33px;
    font-weight: bold;
    font-size: 13px;

    &.profit {
      background-color: green;
    }

    &.loss {
      background-color: red;
    }
  }

  @media print {
    position: relative !important;

    .bx--modal-container {
      width: 100% !important;
      background: #ffffff;
      border-radius: 2px;
    }

    .bx--modal-content {
      padding: 0;
      margin: 0;
    }

    .school-logo {
      height: 0;
      margin-top: 0 !important;
      display: block !important;
      -webkit-print-color-adjust: exact !important;
    }

    .summary-bar {
      background: transparent !important;
      color: #000;
      padding-left: 18px;
      padding-right: 30px;
      font-size: 14px;
    }

    th,
    td,
    .info {
      font-size: 13px !important;
    }
  }
}
