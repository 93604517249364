.ledger-card {
  height: 570px;
  padding-top: 34px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 29px;
  margin-bottom: 50px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  /* ...and now for the proper property */
  transition: 0.5s;

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    color: #252525;
    line-height: 38px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.02em;
    margin-top: 10px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    margin-top: -5px;
    margin-bottom: 0;
    color: #7d819d;
    line-height: 19px;
    text-transform: uppercase;
  }

  .text-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #252525;
  }

  .text-label {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
    color: #aab0c6;
  }

  .text-label-empty {
    font-size: 14px;
    line-height: 22px;
    color: #7d819d;
    margin: 21px 0 31px 0;
  }

  .type-fund-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;

    .type {
      width: 40%;
    }
  }

  .divider {
    height: 65px;
    width: 1px;
    background-color: #f3f3f3;
  }

  .icon {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .line {
    margin: 10px 0 0 0;
    border-top: 1px solid #f3f3f3;
  }

  .line-2 {
    margin-top: 10px;
  }

  .link {
    color: #164d9d;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    margin-top: 34px;
  }

  .link-icon {
    stroke-width: 3;
    color: #164d9d;
  }

  .list-container {
    padding-bottom: 0;
    margin-top: 22px;
    height: 240px;

    .ledger-account {
      margin-bottom: 10px;
    }
  }

  .add-new-ledger-account {
    margin-top: 34px;

    .a {
      font-weight: bold;
    }
  }

  .category {
    height: 77px;
    margin-top: 10px;
    background: #f9f9fb;
    border-radius: 2px;
    padding: 28px;
  }

  .edit-category {
    font-size: 12px;
    line-height: 20px;
    color: #164d9d;
    float: right;
  }

  .delete-category {
    color: #da1e28;
    width: 20%;
    float: right;
  }
}
