.birthday {
  display: flex;
  align-items: center;
  margin-right: 5px;

  span {
  }

  .img,
  img {
    width: 35px !important;
    cursor: pointer;
  }
}
