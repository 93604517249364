.add-employee-modal {
  .bx--modal-container {
    width: 60% !important;

    @media (min-width: 42rem) {
      max-width: none;
    }
  }

  .bx--modal-header {
    padding: 30px 29px 0 29px;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding: 0 29px;
  }

  @media (min-width: 42rem) {
    .bx--tabs__nav {
      flex-direction: row;
      background: none;
      box-shadow: none;
      z-index: auto;
      transition: inherit;
      width: 100%;
    }
  }

  .photo {
    width: 156px;
    height: 156px;
    border-radius: 50%;
    background: #164d9d;
    margin-top: 67px;
    margin-bottom: 26px;
    color: #ffffff;
    text-align: center;
  }

  .camera {
    margin-top: 73px;
  }

  .photo-text {
    width: 275px;
  }

  .btn-actions {
    display: flex;
    justify-content: space-between;
  }

  .custom-btn {
    padding-left: 40px;
    padding-right: 40px;
  }

  .bx--text-area__wrapper {
    width: 100%;
  }

  .upload-btn {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    .bx--btn {
      width: 150px;
      max-width: 150px;
      padding-right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
