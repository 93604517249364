.balance-sheet-modal {
  z-index: 10000 !important;

  .bx--modal-container {
    width: 876px;
    background: #ffffff;
    border-radius: 2px;
    max-width: 100%;
  }

  .bx--modal-header {
    display: none;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding-left: 90px;
    padding-right: 90px;
  }

  .school-logo {
    margin-top: 45px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #7d819d;
    background-size: cover;
  }

  .hr {
    height: 1px;
    border: 1px solid #e7e7e7;
  }

  .bx--data-table {
    th,
    td {
      padding-left: 24px;
      padding-right: 33px;
    }
  }

  .summary-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    height: 61px;
    background: #164d9d;
    color: #ffffff;
    border-radius: 2px;
    padding-left: 24px;
    padding-right: 33px;
    font-weight: bold;
    font-size: 12px;
  }

  @media print {
    position: relative !important;

    .not-authentic {
      display: block;
    }

    .bx--modal-container {
      width: 100% !important;
      background: #ffffff;
      border-radius: 2px;
    }

    .bx--modal-content {
      padding: 0;
      margin: 0;
    }

    .summary-bar {
      background: transparent;
      color: #000;
      padding-left: 18px;
      padding-right: 30px;
      font-size: 14px;
    }

    .pic,
    .school-logo {
      display: block !important;
      -webkit-print-color-adjust: exact !important;
    }
  }
}
