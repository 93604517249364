.employee-card {
  padding: 30px;
  margin-bottom: 50px;

  cursor: pointer;

  &:hover {
    background-color: rgba($color: #164d9d, $alpha: 0.03);
  }

  .icon {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 1 #f3f3f3;
    background: #7d819d;
    background-size: cover;
  }

  .top-content {
    margin: 0;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    color: #252525;
    line-height: 38px;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -0.02em;
    margin-top: 10px;
    margin-bottom: -10px;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    color: #7d819d;
    text-transform: uppercase;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .text-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #252525;
  }

  .text-label {
    font-size: 14px;
    line-height: 22px;
    color: #aab0c6;
  }

  .line {
    height: 0.8px;
    margin: 15px -8px 15px -8px;
    border-top: 1px solid #f3f3f3;

    &.second {
      margin-top: 15px;
    }
  }

  .emp {
    padding-right: 27px;
  }

  .gnd {
    padding-left: 17px;
  }

  .divider {
    height: 50px;
    width: 1px;
    margin: -5px 5px 0 5px;
    border-left: 2px solid #f3f3f3;
    &.small {
      height: 25px;
    }
    &.medium {
      height: 30px;
    }
  }
}
