.slide-container {
  bottom: 10%;
  max-width: 80%;
  position: absolute;
  margin: auto 10%;

  /* [SLIDER] */
  #slider,
  #slider .slide {
    max-width: 100%;
    height: 200px;
  }
  #slider {
    overflow: hidden;
    margin: 0 auto;
    font-size: 1.2em;
  }
  #slider .container {
    position: relative;
    bottom: 0;
    right: 0;
    animation: slide-animation 60s infinite;
  }
  #slider .slide {
    position: relative;
    box-sizing: border-box;
  }

  @keyframes slide-animation {
    0% {
      opacity: 0;
      bottom: 0;
    }
    4% {
      opacity: 1;
      bottom: 0;
    }
    11% {
      opacity: 1;
      bottom: 0;
    }
    15% {
      bottom: 0;
    }
    20% {
      bottom: 0;
    }
    30% {
      bottom: 0;
    }
    40% {
      bottom: 100%;
    }
    50% {
      bottom: 100%;
    }
    55% {
      bottom: 100%;
    }
    65% {
      bottom: 100%;
    }
    75% {
      bottom: 200%;
    }
    80% {
      bottom: 200%;
    }
    90% {
      bottom: 200%;
    }
    98% {
      bottom: 200%;
    }
    100% {
      opacity: 1;
      bottom: 300%;
    }
  }
  /* [ANIMATION] 
  @keyframes slide-animation {
    0% { 
      opacity: 0;
      bottom: 0;
    }
    11% {
      opacity: 1;
      bottom: 0; 
    }
    22% { bottom: 100%; }
    33% { bottom: 100%; }
    44% { bottom: 200%; }
    55% { bottom: 200%; }
    66% { bottom: 300%; }
    77% { bottom: 300%; }
    88% {
      opacity: 1;
      bottom: 400%; 
    }
    99% {
      opacity: 1;
      bottom: 400%; 
    }
    100% {
      opacity: 0;
      bottom: 400%;
    }
  }
  */
  /* [DOES NOT MATTER] 
  html, body {
    font-family: arial;
    background: #ffe9d8;
  }*/
  h1 {
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 129.3%;
    color: #ffffff;
  }
}
