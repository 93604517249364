.academic-year-card {
  max-width: 300px;
  padding-top: 34px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 34px;
  margin-bottom: 50px;

  cursor: pointer;

  &:hover {
    background-color: rgba($color: #164d9d, $alpha: 0.03);
  }

  .status-tag {
    margin-top: 5px;
    font-weight: bold;
    float: right;

    &.false {
      color: #121619;
      background-color: rgba($color: #dde1e6, $alpha: 0.4);
    }

    &.pending {
      color: #9b7604;
      background-color: rgba($color: #f2c94c, $alpha: 0.4);
    }

    &.true {
      color: #219653;
      background-color: rgba($color: #6fcf97, $alpha: 0.4);
    }

    &.Closed {
      color: #da1e28;
      background-color: rgba($color: #da1e28, $alpha: 0.4);
    }
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    color: #252525;
    line-height: 38px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.02em;
    margin-top: 10px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    margin-top: -5px;
    margin-bottom: 0;
    color: #7d819d;
    line-height: 19px;
    text-transform: uppercase;
  }

  .text-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #252525;
  }

  .text-label {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
    color: #aab0c6;
  }

  .variance {
    &.positive {
      color: #24a249;
    }

    &.negative {
      color: #da1e28;
    }
  }

  .icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .line {
    margin: 10px 0 0 0;
    border-top: 0.4px solid #f3f3f3;
  }

  .link-icon {
    stroke-width: 3;
    color: #164d9d;
  }

  .view-btn {
    width: 100%;
    margin-top: 20px;
    background: #164d9d;
    font-weight: bold;
    text-transform: uppercase;
  }
}
