.summary-card {
  height: 230px;
  width: 100%;
  max-width: 300px;
  padding: 33px 29px 0px 29px;
  margin-bottom: 30px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;

  // add color
  &.true {
    background-color: #164d9d;

    .description,
    .value,
    .actions > .link {
      color: #fff;
    }
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    background: #fff;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    // transform
    &.true {
      svg {
        transform: rotate(-180deg);
      }
    }

    &.green {
      background: #24a249;
    }

    &.red {
      background: #da1e28;
    }

    &.orange {
      background: #fc7b1e;
    }
  }

  .card-image {
    width: 46px;
    height: 46px;
  }

  .description {
    font-size: 12px;
    margin-top: 20px;
    color: #7d819d;
    line-height: 19px;
    text-transform: uppercase;
  }

  .value {
    color: #252525;
    line-height: 38px;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: -0.02em;

    &.true {
      color: #da1e28;
    }
  }

  .actions {
    display: flex;
    justify-content: space-around;
    margin-top: 32px;

    &.true {
      display: block;

      .link {
      }
    }

    .link {
      color: #164d9d;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      text-decoration: none;
    }

    .line {
      height: 50px;
      width: 1px;
      margin-top: -20px;
      background-color: #f3f3f3;
    }
  }
}
