.add-payroll-modal {
  .bx--modal-container {
    width: 434px;
    background: #f9f9fb;
  }

  .bx--modal-header {
    padding: 30px 29px 0 29px;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding: 0 29px;
  }
}
