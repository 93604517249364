.billing {
  .bx--search-magnifier {
    fill: #aab0c6;
  }

  .card-add-billing {
    padding-top: 20px;
    height: 100px;
    text-align: center;
    background: #164d9d;
    border-radius: 2px;
    color: white;

    .text-label {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .blank {
    color: #121619;
  }

  .voided {
    color: #fc7b1e;
  }

  .generated {
    color: #219653;
  }

  .bx--form-item {
    width: initial;
  }
}
