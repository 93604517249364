.academic-year {
  .card-add-academic-year {
    padding-top: 40px;
    height: 160px;
    text-align: center;
    background: #164d9d;
    border-radius: 2px;
    color: white;

    .text-label {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .bx--search-magnifier {
    fill: #aab0c6;
  }

  .create-new {
    font-size: 14px;
    color: #164d9d;
    margin-left: 15px;
  }
}
