.trial-balance-page {
  .bx--search-magnifier {
    fill: #aab0c6;
  }

  .variance-bar {
    padding: 32px 24px 27px 24px;
    border-radius: 2px;
  }

  @media print {
    display: none;
  }
}
