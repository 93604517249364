.donut-container {
  max-width: 200px;
  margin: 0 auto;
}

.chart-box {
  padding: 24px 17px;
  background: #ffffff;
  border-radius: 2px;
  margin-top: 20px;

  .chart-tabs {
    margin: auto;

    .chart-tab-item {
      padding-top: 10px;
      border-top: solid #aab0c6 3px;

      &.true {
        border-color: #164d9d;
        color: #164d9d;
      }
    }
  }

  &.hide-legend {
    .bx--cc--legend .clickable {
      visibility: hidden;
    }
  }
}

svg.bx--cc--chart-svg text {
  font-weight: normal !important;
  fill: #164d9d !important;
}

g.bx--cc--legend g.legend-item text {
  font-size: 12px;
  fill: #164d9d;
}

svg.bx--cc--chart-svg .donut-figure {
  visibility: hidden;
}

.bx--cc--axes g.axis g.tick text {
  color: #164d9d !important;
  fill: #164d9d !important;
  font-size: 12px;
}

g.bx--cc--scatter circle.dot.unfilled {
  visibility: hidden;
}

.bx--cc--line path.line {
  stroke-width: 4 !important;
}
