.card-menu {
  position: absolute;
  top: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 16px rgba(170, 176, 198, 0.2);
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  width: 176px;
  right: -150px;
  z-index: 1000;

  .separator {
    border: 1px solid #f3f3f3;
  }

  .menu-item {
    text-transform: uppercase;
    margin-top: 27px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #252525;

    &.primary {
      color: #164d9d;
    }

    &.success {
      color: #24a249;
    }

    &.danger {
      color: #da1e28;
    }
  }
}
