$feature-flags: (
  ui-shell: true,
);

@import '~carbon-components/scss/globals/scss/styles.scss';

// If you want to import the grid
@import '~carbon-components/scss/globals/grid/grid';

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Axiforma Regular';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Regular'),
    url('./assets/fonts/Kastelov  Axiforma Regular.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Italic'),
    url('./assets/fonts/Kastelov  Axiforma Italic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Thin';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Thin'),
    url('./assets/fonts/Kastelov  Axiforma Thin.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Thin Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Thin Italic'),
    url('./assets/fonts/Kastelov  Axiforma Thin Italic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Book';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Book'),
    url('./assets/fonts/Kastelov  Axiforma Book.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Light';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Light'),
    url('./assets/fonts/Kastelov  Axiforma Light.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Book Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Book Italic'),
    url('./assets/fonts/Kastelov  Axiforma Book Italic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Light Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Light Italic'),
    url('./assets/fonts/Kastelov  Axiforma Light Italic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Medium';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Medium'),
    url('./assets/fonts/Kastelov  Axiforma Medium.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Medium Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Medium Italic'),
    url('./assets/fonts/Kastelov  Axiforma Medium Italic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma SemiBold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma SemiBold'),
    url('./assets/fonts/Kastelov  Axiforma SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma SemiBold Italic'),
    url('./assets/fonts/Kastelov  Axiforma SemiBold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Bold'),
    url('./assets/fonts/Kastelov  Axiforma Bold.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Bold Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Bold Italic'),
    url('./assets/fonts/Kastelov  Axiforma Bold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma ExtraBold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma ExtraBold'),
    url('./assets/fonts/Kastelov  Axiforma ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma ExtraBold Italic'),
    url('./assets/fonts/Kastelov  Axiforma ExtraBold Italic.woff')
      format('woff');
}

@font-face {
  font-family: 'Axiforma Black';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Black'),
    url('./assets/fonts/Kastelov  Axiforma Black.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Black Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Black Italic'),
    url('./assets/fonts/Kastelov  Axiforma Black Italic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Heavy';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Heavy'),
    url('./assets/fonts/Kastelov  Axiforma Heavy.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Axiforma Heavy Italic'),
    url('./assets/fonts/Kastelov  Axiforma Heavy Italic.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Axiforma Regular', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//loading
