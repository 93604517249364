.receive-payment-modal {
  .bx--modal-container {
    width: 780px;
    background: #f9f9fb;
  }

  .bx--modal-header {
    padding: 30px 29px 0 36px;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding: 0 43px 29px 36px;
  }

  .bx--data-table {
    td {
      vertical-align: middle;
    }
  }

  .bx--text-area,
  .bx--text-area__wrapper {
    width: 100%;
  }

  .student-result-box {
    padding: 20px;
    background-color: #fff;
    position: absolute;
    width: 328px;
    box-shadow: 0px 10px 16px rgba(170, 176, 198, 0.2);
    border-radius: 5px;
    z-index: 1000;

    .student-item {
      cursor: pointer;
    }
  }
}
