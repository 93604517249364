.main-header-container {
  background-image: url('../../assets/images/header-bg.png');
  background-color: #164d9d;
  border-bottom: none;
  height: 60px;
  margin: 0px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  //padding-left: 200px;
  //padding-right: 200px;

  /* @media screen and (max-width: 1020px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (max-width: 900px) {
    padding-left: 20px;
    padding-right: 20px;
  }
 */

  /* .header-inner{
      max-width: 1680px;
      margin-left: auto;
      margin-right: auto;

  } */

  .bx--header {
    padding-left: 200px;
    padding-right: 200px;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1020px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (max-width: 900px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  //bootstrap

  @media (max-width: 575.98px) {
    .school-name {
      display: none;
    }
  }

  /* .header-inner {
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    max-width: 1140px !important;

    @media screen and (max-width: 1020px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (max-width: 900px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  } */

  .bx--header {
    background-color: transparent;
    border-bottom: none;
    height: 60px;
    width: 100%;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: unset;
    left: unset;
    right: unset;

    margin: 0 auto;

    .notification-icon {
      margin-top: 17px;
      cursor: pointer;
      position: relative;

      &.new-notifications {
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
        }

        animation: fadeIn 1s infinite alternate;
      }
    }

    .school-name {
      font-weight: bold;
      font-style: normal;
    }

    .bx--header__nav::before {
      background-color: #fff;
    }

    .bx--header__submenu {
      list-style: none;
    }

    .bx--header__menu-item[role='menuitem'] {
      color: #fff;
    }

    .bx--header__name {
      padding-left: 0px;
    }

    .bx--header__menu-arrow {
      fill: #fff;
    }

    .bx--header__submenu {
      height: 60px;
    }

    @media print {
      display: none;
    }
  }
}
