.student-card {
  padding: 30px;
  margin-bottom: 50px;

  cursor: pointer;

  &:hover {
    background-color: rgba($color: #164d9d, $alpha: 0.03);
  }

  .class {
    width: 50%;
    text-align: center;
  }

  .gender {
    width: 20%;
  }

  .grad-year {
    width: 20%;
    text-align: right;
  }

  .icon {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 1 #f3f3f3;
    background: #7d819d;
    background-size: cover;
  }

  .top-content {
    margin: 0;
  }

  .menu {
    float: right;
    margin-top: -50px;
    margin-right: -15px;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    color: #252525;
    line-height: 38px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.02em;
    margin-top: 10px;
    margin-bottom: -10px;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    color: #7d819d;
    text-transform: uppercase;
  }

  .make-payment {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #24a249;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .text-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #252525;
  }

  .text-label {
    font-size: 14px;
    line-height: 22px;
    color: #aab0c6;
  }

  .line {
    height: 0.8px;
    margin: 20px -8px 20px -8px;
    border-top: 1px solid #f3f3f3;

    &.second {
      margin-top: 20px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    .payments,
    .bills {
      width: 40%;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #164d9d;
    }

    .bills {
      text-align: right;
    }
  }

  .divider {
    height: 50px;
    width: 1px;
    margin: -5px 5px 0 5px;
    border: 1px solid #f3f3f3;
    &.small {
      height: 25px;
    }
    &.medium {
      height: 30px;
    }
  }

  .loading {
    display: flex;
    background-color: #e2e2e2;

    &.value {
      height: 20px;
      width: 40%;
      margin-top: 10px;
    }

    &::after {
      content: '';
      width: 100%;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.5),
        transparent
      );
      animation: loading 1.5s infinite;
    }
  }

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
}
