.dashboard {
  padding-left: 175px;
  padding-right: 175px;
  margin-top: 100px;

  #search {
    font-size: 14px;
  }

  .bx--search-magnifier {
    fill: #aab0c6;
  }

  @media print {
    display: none !important;
  }
}
