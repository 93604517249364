.trial-balance-modal {
  z-index: 10000 !important;

  .bx--modal-container {
    width: 876px;
    background: #ffffff;
    border-radius: 2px;
  }

  .bx--modal-header {
    display: none;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding-left: 90px;
    padding-right: 90px;
  }

  .school-logo {
    margin-top: 45px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #7d819d;
    background-size: cover;
  }

  .hr {
    height: 1px;
    border: 1px solid #e7e7e7;
  }

  .bx--data-table {
    th,
    td {
      padding-left: 24px;
      padding-right: 33px;
    }
  }

  .header-bar {
    margin-top: 20px;
    height: 61px;

    .bx--data-table {
      td {
        padding-top: 20px;
        font-weight: bold;
        font-size: 12px;
        color: #164d9d;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }

  .grand-total-bar {
    margin-top: 20px;
    height: 61px;
    background: #164d9d;
    border-radius: 2px;
    font-weight: bold;
    font-size: 12px;

    .bx--data-table {
      th,
      td {
        padding-top: 20px;
        color: #ffffff;
      }
    }
  }

  @media print {
    position: relative !important;

    .not-authentic {
      display: block;
    }

    .bx--modal-container {
      width: 100% !important;
      background: #ffffff;
      border-radius: 2px;
    }

    .bx--modal-content {
      padding: 0;
      margin: 0;
    }

    th,
    td,
    .info {
      font-size: 13px !important;
    }

    .pic,
    .school-logo {
      display: block !important;
      -webkit-print-color-adjust: exact !important;
    }
  }
}
