.budget-expandable {
  th,
  td {
    width: 20%;
  }
  margin-top: 10px;

  &.true {
    margin-top: 30px;
  }

  .table-row-bg-primary {
    td,
    th {
      background: #164d9d;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .row-total {
    td {
      font-weight: bold;
      color: #252525;
    }
  }
  .total-revenue,
  .total-expense {
    td,
    th {
      background: #f3f3f3 !important;
      color: #164d9d;
      font-weight: bold;
    }
  }
}
