.custom-btn {
  border-radius: 5px;
  height: 55px;
  display: inline-block;
  text-align: center;
  padding: 0;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  &.primary {
    background-color: #164d9d;
  }

  &.secondary {
    background-color: #000;

    &.light {
      background-color: #565656;
    }
  }

  &.green {
    color: #24a249;
  }

  &.success {
    background-color: #24a249;
  }

  &.danger {
    background-color: #da1e28;
  }

  &.orange {
    background-color: #fc7b1e;
  }

  &.ash {
    background-color: #f3f3f3;
    color: #164d9d;

    &:hover {
      background-color: #164d9d;
      color: #fff;
    }
  }

  &.outline {
    background-color: transparent;
    border: 1px solid;
    box-sizing: border-box;

    &.no-border {
      border: 0 !important;
    }

    &.no-hover {
      &:hover {
        background-color: initial !important;
        color: initial !important;
      }
    }

    &.primary {
      border-color: #164d9d;
      color: #164d9d;

      &:hover {
        background-color: #164d9d;
        color: #fff;
      }
    }

    &.secondary {
      border-color: #000;
      color: #000;

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }

    &.green {
      border-color: #24a249;
    }

    &.orange {
      border-color: #fc7b1e;
      color: #fc7b1e;
    }

    &.success {
      border-color: #24a249;
      color: #24a249;
    }

    &.danger {
      border-color: #da1e28;
      color: #da1e28;

      &:hover {
        background-color: #da1e28;
        color: #fff;
      }
    }
  }

  /* pill type*/
  &.pill {
    border-radius: 50px;
    height: 42px !important;
    border: 0;

    cursor: pointer;

    &:hover {
      color: #164d9d;
      background-color: rgba($color: #164d9d, $alpha: 0.1);
    }

    &:focus {
      outline-color: transparent;
    }

    &.primary:hover {
      background-color: #164d9d;
      color: #fff;
    }
  }

  &.f-width {
    width: 100%;
  }
}
