body {
  background-color: #f9f9fb;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

//reduce the space in table rows when printing
.bx--modal-container {
  .bx--data-table tr {
    height: 2rem;
  }

  .bx--data-table td,
  .bx--data-table th {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 13px;
  }
}

/* move to a new page after this element */
.page-break-after-always {
  page-break-after: always;
}

@media print {
  .bx--data-table tr {
    height: 1.5rem;
  }

  .bx--data-table td,
  .bx--data-table th {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.page-container {
  padding-left: 200px;
  padding-right: 200px;
  margin-top: 80px;
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1020px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (max-width: 900px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media print {
    display: none;
  }
}

.bx--data-table {
  max-width: 100%;
}

//custom table container
.table-container {
  max-width: 100%;
  overflow-x: auto;
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    overflow-x: auto;
    max-width: 760px;
  }
}

.bx--modal-container {
  max-width: 90%;
}

.pointer {
  cursor: pointer;
}

.bx--form-item {
  width: 100%;
}

.bx--text-area,
.bx--text-input,
.bx--search-input,
.bx--select-input,
.bx--list-box__field,
.bx--date-picker__input {
  height: 55px !important;
  font-size: 16px;
  color: #565656;
  background-color: #fff;
  border-bottom: 1px solid #aab0c6;

  &:focus,
  &:active {
    outline: 2px solid #164d9d;
  }

  &::placeholder {
    color: #aab0c6;
  }
}

.bx--text-area {
  width: 100% !important;
  height: auto !important;
}

.bx--text-area__wrapper {
  width: 100% !important;
}

.bx--date-picker,
.bx--select-input,
.bx--select,
.bx--date-picker-container,
.bx--date-picker__input,
.bx--date-picker.bx--date-picker--single .bx--date-picker__input {
  width: 100% !important;
}

.bx--date-picker.bx--date-picker--single .bx--date-picker-container,
.bx--date-picker__input,
.bx--select-input {
  max-width: none;
}

.bx--search-magnifier {
  fill: #aab0c6;
}

.bx--data-table {
  th {
    background: #f3f3f3;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #252525;
    padding-top: 20px;
    padding-bottom: 15px;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #565656;
  }
}

.table-white {
  td {
    background: #ffffff !important;
  }
}

.table-transparent {
  background: transparent;

  tbody,
  td,
  tr {
    background: transparent;
    border-color: transparent;
  }
}

.f-width {
  width: 100%;
}

.v-height {
  height: 100vh;
}

.f-height {
  height: 100%;
}

.pic {
  background-size: cover !important;
}

.h-24 {
  font-weight: bold;
  font-size: 24px;
  line-height: 39px;
  color: #252525;
}

.h-22 {
  font-weight: bold;
  font-size: 22px;
  line-height: 39px;
  color: #252525;
}

.h-20 {
  font-weight: bold;
  font-size: 20px;
  line-height: 39px;
  color: #252525;
}

.h-18 {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #252525;
}

.h-14 {
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  color: #252525;
}

.h-12 {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #252525;
}

.link {
  color: #164d9d;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.v-align-middle {
  vertical-align: middle;
}

.text-center {
  text-align: center !important;
}

.text-white {
  color: #ffffff;
}

.text-dark {
  color: #252525;
}

.text-primary {
  color: #164d9d;
}

.text-success {
  color: #24a249 !important;
}

.text-danger {
  color: red !important;
}

.text-orange {
  color: #fc7b1e !important;
}

.text-primary-dark {
  color: hsl(218, 60%, 21%);
}

.text-grey {
  color: #7d819d;
}

.text-grey-light {
  color: #aab0c6;
}

.text-grey-dark {
  color: #565656;
}

.font-bold {
  font-weight: bold;
  font-family: 'Axiforma Bold';
}

.font-600 {
  font-weight: 600;
}

.font-500 {
  font-weight: 500;
}

.font-normal {
  font-weight: normal;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 14px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.bx--btn .text-22 {
  font-size: 22px;
}

.text-23 {
  font-size: 23px;
}

.text-24 {
  font-size: 24px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-right {
  text-align: right !important;
}

.border-0 {
  border: thin 0 white;
}

.nml {
  margin-left: 0;
}
.nm {
  margin: 0;
}

.nmb {
  margin-bottom: 0;
}

.nmt {
  margin-top: 0;
}

.mt-5 {
  margin-top: 05px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}

.mt-33 {
  margin-top: 33px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-47 {
  margin-top: 47px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-17 {
  margin-left: 17px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-19 {
  margin-left: 19px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-85 {
  margin-left: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.npl {
  padding-left: 0;
}

.npr {
  padding-right: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.bg-white {
  background: #ffffff;
}

.bg-primary {
  background: #164d9d;
}

.no-decoration {
  text-decoration: none;
}

.w-100 {
  width: 100px;
}

.w-200 {
  width: 200px;
}

.w-300 {
  width: 300px;
}

.w-400 {
  width: 400px;
}

.w-500 {
  width: 500px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.d-block {
  display: block;
}

.tag-f-w {
  display: block;
  padding: 10px 20px;
  text-align: center;
  font-size: 15px;
  height: auto;
}

.d-flex {
  display: flex;

  &.flex-end {
    justify-content: flex-end;
  }

  &.align-items-center {
    align-items: center;
  }
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.d-flex-space-around {
  display: flex;
  justify-content: space-around;
}

.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-items-right {
  justify-items: right;
}

.img-f-w-obj-cover {
  width: 100%;
  object-fit: cover;
  max-height: 100vh;
}

.grey-hr {
  border-top: 1px solid #e7e7e7;
}

.padding-0 {
  padding: 0;
}

.margin-0 {
  margin: 0;
}

.fixed-right {
  position: fixed;
  right: 0;

  margin-right: 0;
}

.capitalize {
  text-transform: capitalize !important;
}

.bx--modal {
  position: fixed;
  min-width: 100%;
  min-height: 100%;

  .bx--modal-container {
    height: auto;
    max-height: 90%;
  }
}

.print-modal {
  .bx--modal-container {
    height: 90%;
    max-height: 90% !important;

    .bx--modal-content {
      height: 100% !important;
      flex: 1;
      bottom: 0;
      padding: 0;
    }
  }

  .modal-inner {
    height: 100% !important;
    display: flex;
    flex-direction: column;

    .inner-header {
      padding: 5px 40px;
    }

    .inner-content {
      flex: 1;
      padding: 20px 40px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        background-color: #aab0c6;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #aab0c6;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #252525;
      }
    }

    .inner-footer {
      padding: 30px 40px;
      width: 100%;
      margin: 0;
      overflow: hidden;
    }
  }

  @media print {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    overflow-y: scroll;
    height: auto;
    width: 100%;

    .bx--modal-container {
      position: relative;
      width: 100% !important;
      max-width: 100% !important;
      height: auto !important;
      max-height: auto !important;
      display: block;
      padding: 0;
      margin: 0;

      .bx--modal-content {
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    .inner-header,
    .inner-footer {
      display: none;
    }

    .inner-content {
      height: auto;
      padding: 0 0 !important;
      overflow-y: unset;
    }
  }
}

//hide
.donut-container,
.chart-holder {
  .layout-child {
    .cds--cc--toolbar {
      display: none;
    }
  }
}

/*
 *  SCROLLBAR STYLING
 */

.scrollbar,
.bx--modal-content {
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: #aab0c6;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #aab0c6;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #252525;
  }
}

.bx--modal-content {
  &::-webkit-scrollbar {
    // width: 8px;
    // background-color: #aab0c6;
  }
}

/*
 * FADE IN STYLING
 */

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.right {
  float: right;
}

/* 
 * DOT ANIMATION
 */
@keyframes blink {
  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: 0.2;
  }
  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }
  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0.2;
  }
}

.dot-loader span {
  font-size: 40px;
  /**
     * Use the blink animation, which is defined above
     */
  animation-name: blink;
  /**
     * The animation should take 1.4 seconds
     */
  animation-duration: 1.4s;
  /**
     * It will repeat itself forever
     */
  animation-iteration-count: infinite;
  /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
  animation-fill-mode: both;
}

.dot-loader span:nth-child(2) {
  /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.2s;
}

.dot-loader span:nth-child(3) {
  /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.4s;
}

@media print {
  button,
  .no-print {
    display: none !important;
  }
}

/*page main loader*/

.page-loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Axiforma;
  background: #e5e5e5;
  height: 100vh;
  width: 100vw;
}

.main-loader {
  color: #2186eb;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 82px;
}

@keyframes loading {
  /**
  * At the start of the animation the dot
  */
  0% {
    /* color: #9CBDDE ; */
    color: linear-gradient(#9cbdde, #9cbdde, #2186eb);
    font-size: 48px;
  }

  25% {
    color: #9cbdde;
    font-size: 49px;
  }

  50% {
    color: #9cbdde;
    font-size: 50px;
  }

  75% {
    color: linear-gradient(#9cbdde, #2186eb, #2186eb);
    font-size: 49px;
  }

  95% {
    color: linear-gradient(#9cbdde, #2186eb, #2186eb);
    font-size: 48px;
  }
}

.main-loader span {
  color: #2186eb;
  font-size: 48px;
  /**
* Use the blink animation, which is defined above
*/
  animation-name: loading;
  /**
* The animation should take 1.4 seconds
*/
  animation-duration: 1.4s;
  animation-timing-function: ease-in-out;
  /**
* It will repeat itself forever
*/
  animation-iteration-count: infinite;
  /**
* This makes sure that the starting style 
* of the animation is applied before the animation starts.
* Otherwise we would see a short flash or would have
* to set the default styling of the dots to the same
* as the animation. Same applies for the ending styles.
*/
  animation-fill-mode: both;
}

.main-loader span:nth-child(2) {
  animation-delay: 0.46s;
}

.main-loader span:nth-child(3) {
  animation-delay: 0.93s;
}
