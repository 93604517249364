.register-asset-modal {
  .bx--modal-container {
    width: 1001px;
    background-color: #f9f9fb;
  }

  .bx--modal-header {
    padding: 30px 43px 0 43px;

    .bx--modal-header__heading {
      color: #164d9d;
      font-weight: bold;
    }

    .bx--modal-close {
      display: none;
    }
  }

  .bx--modal-content {
    padding: 0 43px 29px 43px;
  }
}
